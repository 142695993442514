import ComponentType from "./componentType"

/**
 * 字段参数
 * @typedef { Object } FormItem
 * @property { String } name 字段名称
 * @property { String } [label] 显示名称 空值时 = name
 * @property { String } [fieldType = "string"] 字段类型
 * @property { * } [defaultValue] 默认值
 * @property { * } [value] 值
 * @property { * } [placeholder] 提示信息
 * @property { Function } [transformFn] 值转换函数
 * @property { Boolean } [isValidate = false] 是否校验
 * @property { Array } [rules] 校验规则
 * @property { ComponentType } [componentType = ComponentType.Input] 组件类型
 * @property { String } [inputType = "text"] 输入框类型, 当componentType = ComponentType.Input 有效
 * @property { Boolean } [showTime = true] 是否显示时间
 * @property { String } [dateFormat = "YYYY-MM-DD HH:mm:ss"] 时间格式类型, 当componentType = ComponentType.Date || RangeDate 有效
 * @property { Array } [dataOptions = [] ] 可选择项, 当componentType = ComponentType.Select 有效
 * @property { Function } [getData] 可选择项，当需要异步加载时使用
 * @property { Boolean } [multiple = false] 是否多选
 */

class FormItem {
  /**
   *
   * @param {FormItem} params
   */
  constructor(params) {
    let options = params || {}
    // 字段名称
    this.name = options.name
    // 显示名称
    // this.label = options.label || options.name
    this.label = options.label //系统管理-服务管理需要空白占位
    // 字段类型
    this.fieldType = options.fieldType || "string"
    // 默认值
    this.defaultValue = options.defaultValue
    // 值
    this._value = options.value
    // 提示信息
    this.placeholder = options.placeholder
    // 转换函数
    this.transformFn = options.transformFn
    // 是否校验
    this.isValidate = options.isValidate || false
    // 校验规则
    this._rules = options.rules
    //图片地址
    this.imgUrl = options.imgUrl
    //文字内容(没有input框等，单纯文字)

    this.textWords = options.textWords
    // 组件类型
    this.componentType = options.componentType || ComponentType.Input

    // input 组件时，定义的类型
    this.inputType = options.inputType || "text"

    // date 时间组件时
    // 是否显示时间
    this.showTime = options.showTime != null ? options.showTime : true
    // 时间格式
    this.dateFormat =
      options.dateFormat ||
      (this.showTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD")

    // select 选择框组件是, 可选择项
    this._dataOptions = options.dataOptions || []

    // 异步加载时获取的方法
    this.getData = options.getData

    // 是否多选
    this.multiple = options.multiple || false
    // 是否筛选
    this.filterable = options.filterable

    // 是否禁用
    this.disabled = options.disabled || false

    // 初始值
    this.initData = options.initData || undefined

    //其他属性，type,用于服务管理区分二维三维接口服务
    this.type = options.type

    //props属性
    this.props = options.props
  }

  get value() {
    let value = this._value != null ? this._value : this.defaultValue
    if (this.transformFn) {
      return this.transformFn(value)
    }

    return value
  }

  set value(value) {
    this._value = value
  }

  get rules() {
    if (this.isValidate && Array.isArray(this._rules)) {
      return this._rules
    }
    return null
  }

  get dataOptions() {
    return this._dataOptions.map((x) => {
      // 对象直接返回
      if (typeof x == "object" && x != null) {
        return x
      }
      if (typeof x == "number" || typeof x == "string") {
        return { value: x, text: x }
      }
      return x
    })
  }
}

export default FormItem
