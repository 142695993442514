<!-- 表单过滤条件的查询 -->

<template>
  <el-form
    ref="form"
    :inline="true"
    :model="form"
    :rules="rules"
    v-if="form"
  >
    <el-form-item
      v-for="(item, i) in formItems"
      :key="i"
      :label="item.label"
      :prop="item.name"
      :style="{
        marginRight: item.componentType == 'StartDate' ? '2px' : '24px',
      }"
    >
      <template v-if="item.componentType == ComponentType.Input">
        <el-input
          v-model="form[item.name]"
          :placeholder="item.placeholder ? item.placeholder : ''"
          :type="item.inputType"
          :style="{ width: item.width ? item.width : '160px' }"
          clearable
        />
      </template>
      <template v-else-if="item.componentType == ComponentType.Select">

        <!-- 这里是改变下拉框的地方 -->
        <el-select
          v-model="form[item.name]"
          :placeholder="
            item.placeholder ? item.placeholder : `请选择${item.label}`
          "
          :filterable="item.filterable ? item.filterable:false"

          :disabled="item.disabled"
          :style="{ width: item.width ? item.width : '160px' }"
          @change="selectChange(item.name, form[item.name])"
        >
          <el-option
            v-for="d in item.dataOptions"
            :key="d.value"
            :label="d.text || d.label || d.name"
            :value="d.value"
          >
          </el-option>
        </el-select>
        <!-- 这里是日历功能 -->
      </template>
      <template v-else-if="item.componentType == 'StartDate'">
        <el-date-picker
          v-model="form[item.name]"
          type="date"
          :picker-options="pickerStartDate"
          :placeholder="item.placeholder ? item.placeholder : '开始日期'"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-dateSelect"
        >
        </el-date-picker>
      </template>
      <template v-else-if="item.componentType == 'EndDate'">
        <el-date-picker
          v-model="form[item.name]"
          type="date"
          :picker-options="pickerEndDate"
          :placeholder="item.placeholder ? item.placeholder : '结束日期'"
          value-format="yyyy-MM-dd"
          size="medium"
          prefix-icon="el-icon-dateSelect"
        >
        </el-date-picker>
      </template>
      <template v-else-if="item.componentType == 'Date'">
        <el-date-picker
          v-model="form[item.name]"
          type="date"
          value-format="yyyy-MM-dd"
          size="medium"
          :picker-options="pickerOptions"
          prefix-icon="el-icon-dateSelect"
        >
        </el-date-picker>
      </template>
      <template v-else-if="item.componentType == 'Year'">
        <el-date-picker
          v-model="form[item.name]"
          type="year"
          format="yyyy年"
          value-format="yyyy"
          size="medium"
          :placeholder="
            item.placeholder ? item.placeholder : `请选择${item.label}`
          "
          prefix-icon="el-icon-dateSelect"
        >
        </el-date-picker>
      </template>
      <template v-else-if="item.componentType == 'cascader'">
        <el-cascader
          ref="cascader"
          v-model="form[item.name]"
          :props="item.props"
          style="width: 180px"
          :options="item.dataOptions"
          :show-all-levels="false"
          @change="cascaderChange(item.name,form[item.name])"
          clearable
        ></el-cascader>
      </template>
      <template v-else-if="item.componentType == 'Selects'">
        <el-select
          v-model="form[item.name]"
          multiple
          collapse-tags
          style="width: 150px"
          @change="selectsChange(item.name, form[item.name])"
          :placeholder="
            item.placeholder ? item.placeholder : `请选择${item.label}`
          "
        >
          <!-- collapse-tags -->
          <el-option
            v-for="d in item.dataOptions"
            :key="d.value"
            :label="d.text || d.label || d.name"
            :value="d.value"
          >
          </el-option>
        </el-select>
      </template>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        @click="submit"
      >查询</el-button>
      <el-button
        @click="reset"
        v-if="showReset"
      >重置</el-button>
      <slot name="btn"></slot>
    </el-form-item>
  </el-form>
</template>

<script>
import ComponentType from "./js/componentType";
import { createFormAndRules } from "./js/form";
// import MyDatePicker from "./../../../component/date-picker";
// import MyDateRangePicker from "./../../../component/date-range-picker";

export default {
  components: {
    // MyDatePicker,
    // MyDateRangePicker,
  },
  props: {
    data: {
      type: Array, // 参照 ant/js/form/formItem的参数说明
      default: () => {
        return [];
      },
    },
    showReset: {
      type: Boolean, // 参照 ant/js/form/formItem的参数说明
      default: true,
    },
  },
  data() {
    return {
      ComponentType: ComponentType,
      form: null,
      rules: null,
      formItems: [],
      pickerStartDate: {
        disabledDate: (time) => {
          if (this.form.endDate) {
            return time.getTime() > this.form.endDate;
          }
        },
        //周起始日
        firstDayOfWeek: 1,
      },
      pickerEndDate: {
        disabledDate: (time) => {
          return time.getTime() < this.form.startDate;
        },
        //周起始日
        firstDayOfWeek: 1,
      },
      pickerOptions: {
        //周起始日
        firstDayOfWeek: 1,
      },
    };
  },
  created() {
    let { form, rules, formItems } = createFormAndRules(this.data);
    this.form = form;
    this.rules = rules;
    this.formItems = formItems;
    console.log("form", this.form);
    console.log("formItems", this.formItems);
    this.formItems.map((e) => {
      if (e.initData || Array.isArray(e.initData)) {
        //如果设置了初始值
        if (e.componentType == "select") {
          Number.isFinite(e.initData * 1)
            ? (this.form[e.name] = e.initData * 1)
            : (this.form[e.name] = e.initData);
        }
        if (e.componentType == "Selects") {
          this.form[e.name] = e.initData;
        }
        if (e.componentType == "StartDate" || e.componentType == "EndDate") {
          this.form[e.name] = e.initData;
        }
      }
    });
  },
  watch: {
    form(newVal, oldVal) {
      if (newVal) {
        // console.log("formBIANL", newVal, newVal.startTime);
      }
    },
  },
  methods: {
    cascaderChange(key, arr) {
        this.$emit("change", { key: key, value: arr.length?arr[arr.length - 1]:'' });
    },
    selectChange(key, value) {
      this.$emit("change", { key: key, value: value });
    },
    selectsChange(key, value) {
      this.$emit("change", { key: key, value: value });
    },
    changeFormItem(index, property, value) {
      this.formItems[index * 1][property] = value;
    },
    changeForm(name, value) {
      this.form[name] = value;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form);
        }
      });
    },
    reset() {
      this.$refs.form.resetFields();
      this.$emit("reset");
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 12px;
}
/deep/.el-form-item__label {
  color: #606266;
  font-size: 14px;
  padding-right: 2px;
}
/deep/.el-input__inner {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  height: 36px;
  line-height: 36px;
  color: #333 !important;
}
/deep/.el-input__suffix {
  .el-input__suffix-inner {
    i {
      color: #70ccdc;
    }
  }
}
/deep/.el-select__tags {
  max-width: 300px !important;
}
/deep/.el-tag {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #333 !important;
  margin: 2px 0 2px 4px;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: transparent !important;
}
</style>
